import React from "react";
import { Button, Input } from "antd";
import "./modal.css";

const VideoView = ({ show, onClose, children, item, videoLink }) => {
  if (!show) {
    return null;
  }
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <video width="600" height="700" controls>
          <source src={videoLink} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div style={{ marginTop: "3rem" }}>
          <Button onClick={onClose} type="primary" danger>
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VideoView;
