import React from "react";
import { useState, useRef } from "react";
import Input from "antd/es/input/Input";
import { Button, Typography, TimePicker } from "antd";
import dayjs from "dayjs";
import {
  GreenNotify,
  upload,
  uploadOnS3,
  uploadOnS3Video,
} from "../../helper/helper";
import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
import Loader from "../../components/loader/loader";
import "./style.css";
import { videoIcon } from "../../assets";
import { useNavigate, useNavigation } from "react-router-dom";

const AddNewVideo = () => {
  const [author, setAuthor] = useState("");
  const [title, setTitle] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [image, setImage] = useState("");
  const [duration, setDuration] = useState("");
  const [video, setVideo] = useState("");
  const navigate = useNavigate();
  const fileInputVideoRef = useRef(null);
  const dummyImage =
    "https://novathreadbucket.s3.amazonaws.com/nova-app-1685176470232-dummy.PNG";
  const fileInputImageRef = useRef(null);
  const addNewVideo = () => {
    let getRes = (res) => {
      console.log("res of upload video", res);
      GreenNotify("Video is uploaded successfully");
      navigate("/add-video");
    };

    let body = {
      videoUrl: video,
      thumbnail: image,
      author: author,
      title: title,
      duration: duration,
    };

    callApi("POST", routes.addVideo, body, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };
  const format = "mm:ss";

  const onChange = (time, timeString) => {
    console.log(timeString);
    setDuration(timeString);
  };

  const pickImageFile = () => {
    fileInputImageRef.current.click();
  };

  const pickVideoFile = () => {
    fileInputVideoRef.current.click();
  };

  const disabled =
    author.length !== 0 &&
    title.length !== 0 &&
    image.length !== 0 &&
    video.length !== 0 &&
    duration.length !== 0;

  return (
    <div style={{ marginBottom: "2rem", padding: "3rem", width: "50rem" }}>
      <Loader loading={isloading} />
      <h2>Add new video</h2>
      <Typography.Title level={5} style={{ marginTop: "2rem" }}>
        Author
      </Typography.Title>
      <Input
        style={{ marginTop: "1rem", height: "5rem" }}
        value={author}
        onChange={(e) => setAuthor(e.target.value)}
        placeholder="Author"
      />
      <Typography.Title level={5} style={{ marginTop: "2rem" }}>
        Tittle
      </Typography.Title>
      <Input
        style={{ marginTop: "1rem", height: "5rem" }}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Title"
      />
      <Typography.Title level={5} style={{ marginTop: "2rem" }}>
        Thumbnail
      </Typography.Title>
      <div className="image-upload-container">
        {/* <h1> Add Image</h1> */}
        <img
          onClick={pickImageFile}
          alt="image.jpeg"
          src={image.length !== 0 ? image : dummyImage}
        />
        <input
          type="file"
          ref={fileInputImageRef}
          style={{ display: "none" }}
          onChange={uploadOnS3((url) => setImage(url), setIsLoading)}
          accept="image/*"
        />
      </div>
      <Typography.Title level={5} style={{ marginTop: "2rem" }}>
        Video
      </Typography.Title>
      <div className="video-upload-container">
        {video ? (
          <video
            controls
            alt="video.jpeg"
            src={video}
            style={{ width: "25rem", height: "20rem" }}
          />
        ) : (
          <img
            onClick={pickVideoFile}
            alt="video-icon"
            src={videoIcon}
            style={{
              cursor: "pointer",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
          />
        )}
        <input
          type="file"
          ref={fileInputVideoRef}
          style={{ display: "none" }}
          onChange={uploadOnS3Video((url) => setVideo(url), setIsLoading)}
          accept="video/*"
        />
      </div>
      <Typography.Title level={5} style={{ marginTop: "2rem" }}>
        Duration
      </Typography.Title>
      <div className="time-pick-container">
        <TimePicker
          size="large"
          format={format}
          onChange={onChange}
          defaultValue={dayjs("00:00", format)}
          showNow={false} // Hide the "Now" button if not needed
          style={{ width: "14rem" }}
        />
        <Button
          disabled={!disabled}
          type="primary"
          style={{ marginTop: "2rem", width: "14rem" }}
          onClick={addNewVideo}
        >
          Add video
        </Button>
      </div>
    </div>
  );
};

export default AddNewVideo;
