import React, { useState, useEffect } from "react";
import { Breadcrumb, Table, Image, Input, Button, Modal } from "antd";
import Loader from "../../components/loader/loader";
import { addIcon, editIcon, homeIcon, options, redTrash } from "../../assets";
import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
import ModalDescription from "../../components/modalDescription/modalDescription";
import OptionModal from "../../components/optionModal/optionModal";
import { useNavigate } from "react-router-dom";
import { GreenNotify } from "../../helper/helper";
import EditModal from "../../components/editModal/editModal";
import VideoView from "../../components/videoView/videoView";
// import EditModal from "../../components/editModal/editModal";
// import EditOp from "../../components/editop/EditOp";

const Videos = () => {
  const [isloading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [quotes, setQuotes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalOption, setShowModalOption] = useState(false);
  const [getServiceapi, setGetServiceApi] = useState(false);
  const [popup, setPopup] = useState(false);
  const [videolink, setVideoLink] = useState("");

  const getQuotes = () => {
    let getRes = (res) => {
      console.log("res of get all videos", res);
      setQuotes(res?.data?.data);
    };

    callApi("GET", routes.getAllVideos, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };

  const deleteVideo = (id) => {
    setGetServiceApi(false);
    let getRes = (res) => {
      //   console.log("res of deleteQuote", res);
      GreenNotify("Video is deleted successfully");
      setGetServiceApi(true);
    };

    callApi(
      "DELETE",
      `${routes.getAllVideos}/${id}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  const onclose = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    getQuotes();
  }, [getServiceapi]);

  const columns = [
    {
      title: "Author",
      dataIndex: "author",
      className: "role-name-column-header",
    },
    {
      title: "Title",
      dataIndex: "title",
      align: "center",
      className: "type-name-column-header",
    },
    {
      title: "Thumbnail",
      dataIndex: "thumbnail",
      align: "center",
      className: "type-name-column-header",
    },
    {
      title: "Video",
      dataIndex: "view",
      align: "center",
      className: "type-name-column-header",
    },

    // {
    //   title: "Edit",
    //   dataIndex: "edit",
    //   align: "center",
    //   className: "action-column-header",
    // },
    {
      title: "Delete",
      dataIndex: "delete",
      align: "center",
      className: "action-column-header",
    },
  ];

  const data = quotes?.map((item, index) => {
    return {
      key: index,
      author: item?.author,
      title: item?.title,
      view: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setShowModal(true);
            setVideoLink(item?.videoUrl);
          }}
        >
          <h3>View</h3>
        </div>
      ),
      thumbnail: (
        <div className="product-list-image">
          <Image width={140} src={item?.thumbnail} alt="profile-image" />
        </div>
      ),
      //   edit: (
      //     <div
      //       onClick={() => navigate("/editquote", { state: { item: item } })}
      //       style={{ cursor: "pointer" }}
      //     >
      //       <img src={editIcon} alt="editIcon" />
      //     </div>
      //   ),
      delete: (
        <div
          onClick={() => deleteVideo(item?._id)}
          style={{ cursor: "pointer" }}
        >
          <img src={redTrash} alt="red-trash" />
        </div>
      ),
    };
  });

  const getRowClassName = (record, index) => {
    if (index % 2 === 0) {
      return "server-role-even-row";
    }
    return "server-role-odd-row";
  };

  return (
    <div className="admin-products-main-container">
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Quotes</Breadcrumb.Item>
      </Breadcrumb>
      <div className="configure-server-roles-main-heading-container">
        <h1>Quotes</h1>
        <div
          onClick={() => navigate("/add-video")}
          className="server-roles-add-btn"
        >
          <img src={addIcon} alt="" />
          <p>Add New Video</p>
        </div>
      </div>
      <div className="server-roles-tb-main-container">
        <Table
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          pagination={true}
          className="subscriptionapi-table"
        ></Table>
      </div>
      <VideoView show={showModal} videoLink={videolink} onClose={onclose} />
    </div>
  );
};

export default Videos;
